/* Footer styles */
.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    font-size: 1rem; /* Default font size */
}

/* MEDIA QUERIES FOR RESPONSIVENESS */

/* Mobile devices (up to 768px) */
@media (max-width: 768px) {
    .footer {
        padding: 0.5rem 0; /* Reduce padding on smaller screens */
        font-size: 0.875rem; /* Slightly smaller text on mobile devices */
    }
}
