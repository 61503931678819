/* Fade enter and exit transition styles */
.fade-enter {
  opacity: 0;
  transform: scale(0.98);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.98);
  transition: opacity 300ms, transform 300ms;
}

/* Ensure the content takes up the full height */
.page-transition {
  height: 100%;
}
