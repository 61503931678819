.libraries {
    padding: 2rem 0;
    background-color: #f8f8f8; /* Light background for better readability */
}

.libraries .container {
    text-align: center;
}

.libraries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible grid */
    gap: 2rem;
    justify-items: center;
    align-items: start;
}

.library-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    width: 100%;
    max-width: 400px; /* Maximum width to maintain design consistency */
    text-align: center;
}

.library-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.library-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
    object-fit: cover; /* Ensure image covers the area without distortion */
}

.library-card h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
}

.library-card p {
    margin: 0.5rem 0 0;
    font-size: 1rem;
    color: #555;
}

.library-card a {
    text-decoration: none;
    color: inherit;
}
