/* Ensure the app takes up the full height of the viewport */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* Make sure the content area grows to fill the available space */
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* The header should stay fixed at the top */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #333;
  color: #fff;
  width: 100%;
}

/* Provide enough top padding to prevent content overlap with the fixed header */
.main-content {
  flex: 1;
  padding-top: 60px; /* Adjust this based on your header height */
}

/* Adjust the footer to stick to the bottom when content is short */
footer {
  flex-shrink: 0;
  background-color: #333;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

/* Ensure the main content area grows to fill the available space */
main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
