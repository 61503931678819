.library-details {
    padding: 2rem 0;
}

.library-details .container {
    text-align: center;
}

.library-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.library-image {
    width: 100%;
    max-width: 500px; /* Limit the image width for larger screens */
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

.library-info {
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.library-info h3 {
    margin: 0.5rem 0;
    font-size: 1.25rem;
    color: #333;
}

.library-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #555;
}

.library-info .btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.library-info .btn:hover {
    background-color: #555;
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .library-content {
        flex-direction: row; /* Align image and info side by side on larger screens */
    }

    .library-info {
        text-align: left; /* Align text to the left for larger screens */
    }
}
