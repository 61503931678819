.email-container {
  position: relative;
  width: 100%;
}

.email-input {
  padding-right: 40px; /* Ensure space for the icon on the right */
}

.email-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Position the icon to the right */
  transform: translateY(-50%);
  color: #333;
}

.email-icon:hover {
  color: #007bff;
}

/* Restrict the following styles to only apply to the login/sign-up pages */
.login-page {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.login-container {
  max-width: 400px;
  width: 100%;
  padding: 2rem;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Center the form within the page */
.login-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Take full viewport height */
}

.login-title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input {
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-input {
  padding-right: 40px; /* Ensure space for the icon */
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
}

.password-toggle-icon:hover {
  color: #007bff;
}

.login-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.login-button, .signup-button {
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.login-button {
  background-color: #333;
  color: #fff;
}

.signup-button {
  background-color: #007bff;
  color: #fff;
}

.login-button:hover, .signup-button:hover {
  opacity: 0.9;
}

.forgot-password-text {
  text-align: center;
  color: #007bff;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.forgot-password-text:hover {
  text-decoration: underline;
}

.login-message {
  color: green;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1rem;
}

.login-error {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1rem;
}

.switch-form-text {
  text-align: center;
  margin-top: 1rem;
}

.switch-form-text a {
  color: #007bff;
  text-decoration: none;
}

.switch-form-text a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-container {
    padding: 1.5rem;
  }

  .login-title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .login-input {
    padding: 0.7rem;
    font-size: 0.95rem;
  }

  .login-button, .signup-button {
    padding: 0.7rem 1rem;
    font-size: 0.95rem;
  }

  .forgot-password-text {
    font-size: 0.85rem;
  }

  .login-error, .login-message {
    font-size: 0.85rem;
  }

  .switch-form-text {
    font-size: 0.85rem;
  }
}

